<template src="./SideNavigationMagazineMoneyArticles.html" />

<script>
import { ContentLoader } from 'vue-content-loader';
import { QUERY_MINKABU_MAGAZINE_MONEY_ARTICLES } from '../../../queries/MinkabuMagazineMoneyArticlesQuery';

/**
 * みんかぶマガジンマネー記事一覧コンポーネント
 * @vue-components {SideNavigation/SideNavigationMagazineMoneyArticles}
 * @module components/modules/SideNavigation/SideNavigationMagazineMoneyArticles
 */
export default {
  name: 'SideNavigationMagazineMoneyArticles',
  components: {
    contentLoader: ContentLoader,
  },
  data() {
    return {
      articlesLoad: false,
      minkabuMagazineMoneyArticles: [],
    };
  },
  apollo: {
    minkabuMagazineMoneyArticles: {
      query: QUERY_MINKABU_MAGAZINE_MONEY_ARTICLES,
      skip() {
        return true;
      },
    },
  },
  computed: {
    /**
     * シャドースケルトンの表示判定
     * @returns {Boolean}
     */
    displayShadowSkeleton() {
      return this.$apollo.loading || !this.articlesLoad;
    },
  },
  mounted() {
    this.$apollo.queries.minkabuMagazineMoneyArticles.skip = false;
    this.articlesLoad = true;
  },
  methods: {
    /**
     * @param {String} articleUrl
     * @returns {String}
     */
    generateUrlWithUtmParameters(articleUrl) {
      const utmSource = this.isSp ? 's_minkabu' : 'minkabu';
      const utmMedium = this.isSp ? 'bottom_fix_mag' : 'side_navi_mag';
      const utmCampaign = 'mag_money';
      const url = new URL(articleUrl);
      url.searchParams.set('utm_source', utmSource);
      url.searchParams.set('utm_medium', utmMedium);
      url.searchParams.set('utm_campaign', utmCampaign);
      return url.toString();
    },
  },
};
</script>
