<template src="./StockSearchBanner.html"></template>
<script>
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import UrlGenerateUtil from '../../../../../utils/UrlGenerateUtil';
dayjs.extend(isBetween);

/**
 * 銘スク専用キャンペーンバナーコンポーネント
 * @module Banner/StockSearchBanner
 */
export default {
  name: 'StockSearchBanner',
  computed: {
    /**
     * キャンペーン期間中かどうか
     * @type {boolean}
     */
    isCampaignPeriod() {
      let CAMPAIGN_START_TIME = '2024-12-26 00:00:00';
      let CAMPAIGN_END_TIME = '2025-01-15 23:59:59';
      return dayjs().isBetween(CAMPAIGN_START_TIME, CAMPAIGN_END_TIME, 'day', '[]');
    },
    /**
     * キャンペーンURL
     * @type {string}
     */
    campaignUrl() {
      /**
       * @param {string} path 遷移先のパス
       */
      return UrlGenerateUtil.idMinkabuUrl({
        pathString: 'campaigns/asset_formation_supports_2024_winter',
        currentUrl: window.location.href,
        source: 'minkabu',
        medium: 'banner_stock_search_result',
        campaign: 'asset_formation_supports_2024_winter',
      });
    },
  },
};
</script>
