<template src="./FinancialItemRankingSearchForm.html"></template>
<script>
import FinancialItemRankingFormParameter from './FinancialItemRankingFormParameter';

/*
 * @vue-prop {Object} formElements
 * @vue-data {string} exchangeNameId 市場コード
 * @vue-data {string} sortOrder 表示順
 * @vue-data {string} industrySectorCode 業種コード
 * @vue-computed {FinancialItemRankingFormParameter} rankingFormParameter formElementsから作られた株式ランキングフォーム
 * @vue-computed {Object} currentParams 選択された絞込条件
 * @module FinancialItemRankingSearchForm
 */
export default {
  name: 'FinancialItemRankingSearchForm',
  props: {
    rankingType: {
      type: String,
      default: '',
      required: true,
    },
    formElements: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      exchangeNameId: null,
      sortOrder: null,
      industrySectorCode: null,
    };
  },
  computed: {
    rankingFormParameter() {
      return new FinancialItemRankingFormParameter(this.formElements);
    },
    currentParams() {
      const params = {
        exchange: this.exchangeNameId,
        order: this.sortOrder,
        industry: this.industrySectorCode,
      };
      Object.keys(params).forEach((k) => {
        if (!params[k]) {
          delete params[k];
        }
      });
      return params;
    },
  },
  /**
   * フォーム初期値設定
   */
  mounted() {
    this.exchangeNameId = this.formElements.exchangeNameId;
    this.sortOrder = this.formElements.sortOrder;
    this.industrySectorCode = this.formElements.industrySectorCode;
  },
  methods: {
    /**
     * 選択された絞込条件で遷移する
     */
    submitRankingForm() {
      this.rankingFormParameter.submit(this.currentParams);
    },
    /**
     * 検索ボックスを閉じる
     */
    closeBox() {
      this.$emit('set-box-visible', false);
    },
    /**
     * 絞り込み条件のクリア
     */
    clearForm() {
      this.exchangeNameId = null;
      this.sortOrder = this.formElements.orders[0][0];
      this.industrySectorCode = null;
    },
  },
};
</script>
