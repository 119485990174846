import SideNavigationMagazineMoneyArticles from './SideNavigationMagazineMoneyArticles.vue';
import MkApolloProvider from '../../../utils/MkApolloProvider';

/*
 * 右ナビ マガジン記事表示用 ルートコンポーネント
 */
const SideNavigationMagazineArticles = {
  el: '#v-side-navigation-magazine-money-articles',
  apolloProvider: MkApolloProvider,
  components: {
    SideNavigationMagazineMoneyArticles: SideNavigationMagazineMoneyArticles,
  },
};

export default SideNavigationMagazineArticles;
