import gql from 'graphql-tag';
import { FRAGMENT_LATEST_TRADE_WITH_CHANGE } from './fragments/LatestTradeFragment';

export const QUERY_LATEST_TRADE = gql`
  query latestTradeWithChange($fic: String!) {
    latestTradeWithChange(fic: $fic) {
      ...latestTradeWithChange
    }
  }
  ${FRAGMENT_LATEST_TRADE_WITH_CHANGE}
`;
