import gql from 'graphql-tag';

export const QUERY_MINKABU_MAGAZINE_MONEY_ARTICLES = gql`
  query minkabuMagazineMoneyArticles {
    minkabuMagazineMoneyArticles {
      title
      authorName
      url
      thumbnailUrl
      publishedTime
    }
  }
`;
