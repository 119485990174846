<template src="./Nikkei225Link.html" />
<script>
import { QUERY_FINANCIAL_ITEM } from '../../../queries/FinancialItemQuery';
import { QUERY_LATEST_TRADE } from '../../../queries/LatestTradeQuery';
import { ContentLoader as contentLoader } from 'vue-content-loader';

/**
 * 日経平均ページリンク
 * @vue-components {News/Nikkei225Link}
 * @module components/modules/News/Nikkei225Link
 */
export default {
  name: 'Nikkei225Link',
  components: {
    contentLoader,
  },
  data() {
    return {
      financialItem: null,
      latestTradeWithChange: null,
    };
  },
  apollo: {
    financialItem: {
      query: QUERY_FINANCIAL_ITEM,
      skip() {
        return true;
      },
      variables() {
        return {
          code: this.NIKKEI225CODE,
        };
      },
    },
    latestTradeWithChange: {
      query: QUERY_LATEST_TRADE,
      skip() {
        return true;
      },
      variables() {
        return {
          fic: this.NIKKEI225FIC,
        };
      },
    },
  },
  computed: {
    /**
     * データのロードが完了しているか
     * @returns {Boolean}
     */
    isLoaded() {
      return this.financialItem !== null && this.latestTradeWithChange !== null;
    },
    /**
     * 日経平均の金融アイテムコード
     */
    NIKKEI225CODE() {
      return '100000018';
    },
    /**
     * 日経平均の金融アイテムコードと証券取引所ID
     */
    NIKKEI225FIC() {
      return '100000018.2';
    },
  },
  mounted() {
    this.lazyQuery();
  },
  methods: {
    /**
     * @note mountedされるまでpropsから引数を受け取る事ができないので、mountedでskipをfalseにして、queryを発火させている
     */
    lazyQuery() {
      this.$apollo.queries.financialItem.skip = false;
      this.$apollo.queries.latestTradeWithChange.skip = false;
    },
  },
};
</script>
