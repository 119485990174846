import gql from 'graphql-tag';
import { FRAGMENT_FINANCIAL_ITEM } from './fragments/FinancialItemFragment';

export const QUERY_FINANCIAL_ITEM = gql`
  query financialItem($code: String!) {
    financialItem(code: $code) {
      ...financialItem
    }
  }
  ${FRAGMENT_FINANCIAL_ITEM}
`;
