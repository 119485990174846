import Nikkei225Link from './Nikkei225Link.vue';
import MkApolloProvider from '../../../utils/MkApolloProvider';

/**
 * 日経平均ページリンクルートコンポーネント
 *
 * @vue-components {Nikkei225Link} nikkei225Link 日経平均ページリンク
 * @module Nikkei225
 */
const Nikkei225 = {
  el: '#v-nikkei225link',
  apolloProvider: MkApolloProvider,
  components: {
    nikkei225Link: Nikkei225Link,
  },
};
export default Nikkei225;
