class FinancialItemRankingFormParameter {
  // @param {Object} formParameter フォーム要素オブジェクト
  // @property {Array} exchanges 市場リスト
  // @property {Array} orders 表示順リスト
  // @property {Array} industries 業種リスト
  // @property {string} exchangeNameId 市場コード
  // @property {string} sortOrder 表示順
  // @property {string} industrySectorCode 業種コード
  constructor(formElements) {
    this.exchanges = formElements.exchanges;
    this.orders = formElements.orders;
    this.industries = formElements.industries;

    this.exchangeNameId = formElements.exchangeNameId;
    this.sortOrder = formElements.sortOrder;
    this.industrySectorCode = formElements.industrySectorCode;

    this._url = new URL(window.location);
  }

  // 現在の絞込条件で遷移する
  // @return {void}
  submit(currentParams) {
    window.location.assign(this._action(currentParams));
  }

  _action(currentParams) {
    this._url.search = new URLSearchParams(currentParams);
    return this._url.toString();
  }

  _toHash(matrix) {
    let hash = {};
    matrix.forEach(([k, v]) => { if (k) { hash[k] = v; } });
    hash['_default_'] = matrix[0][1];
    return hash;
  }
}
export default FinancialItemRankingFormParameter;
